import { POST_PAGE_SOCIAL_SHARE_CLICKED } from '../../actions/post-page-social-share-clicked';
import { getQueryLocale } from '../../selectors/locale-selectors';
import { getPost } from '../../selectors/post-selectors';

const handler = ({ payload }, state) => {
  const post = getPost(state, payload.postId) || {};
  const language = getQueryLocale(state);

  return {
    evid: 242,
    language,
    origin: 'post',
    click_name: payload.type,
    translation_id: post.translationId,
    post_id: payload.postId,
    post_stable_id: post.id,
    is_demo: post.isDemo,
    eventMeta: {
      description: 'When the user clicks on an icon at the bottom of the post',
    },
  };
};

export const uouPostPageSocialShareClickedEvent = {
  [POST_PAGE_SOCIAL_SHARE_CLICKED]: handler,
};
