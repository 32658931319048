import { __assign, __awaiter, __generator } from "tslib";
import { get, omit } from 'lodash';
import { getTPASettingsData } from '../selectors';
import createAction from '../services/create-action';
import { getDefaultSiteLocale, getLocales } from '../store';
var config = {
    apiBaseUrl: '/_api/app-settings-service',
};
export var FETCH_TPA_SETTINGS_REQUEST = 'tpaSettings/FETCH_SETTINGS_REQUEST';
export var FETCH_TPA_SETTINGS_SUCCESS = 'tpaSettings/FETCH_SETTINGS_SUCCESS';
export var FETCH_TPA_SETTINGS_FAILURE = 'tpaSettings/FETCH_SETTINGS_FAILURE';
export var fetchTPASettingsRequest = createAction(FETCH_TPA_SETTINGS_REQUEST);
export var fetchTPASettingsSuccess = createAction(FETCH_TPA_SETTINGS_SUCCESS);
export var fetchTPASettingsFailure = createAction(FETCH_TPA_SETTINGS_FAILURE);
export var fetchTPASettings = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.host, host = _c === void 0 ? 'NA' : _c, _d = _b.state, state = _d === void 0 ? 'PUBLISHED' : _d, language = _b.language;
    return function (dispatch, getState, _a) {
        var request = _a.request;
        return __awaiter(void 0, void 0, void 0, function () {
            var languageCode, response, error_1;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        dispatch(fetchTPASettingsRequest());
                        languageCode = language ||
                            (getLocales(getState()) &&
                                get(getDefaultSiteLocale(getState()), 'id', null));
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, request("/v1/settings/instances?state=" + state + "&host=" + host + (languageCode ? "&languageKey.languageCode=" + languageCode : ''), config)];
                    case 2:
                        response = _c.sent();
                        dispatch(fetchTPASettingsSuccess(__assign({ settings: response.settings }, (languageCode && {
                            translations: (_b = {}, _b[languageCode] = response.translations, _b),
                        }))));
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _c.sent();
                        dispatch(fetchTPASettingsFailure());
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
};
export var UPDATE_TPA_SETTINGS_REQUEST = 'tpaSettings/UPDATE_SETTINGS_REQUEST';
export var UPDATE_TPA_SETTINGS_SUCCESS = 'tpaSettings/UPDATE_SETTINGS_SUCCESS';
export var UPDATE_TPA_SETTINGS_FAILURE = 'tpaSettings/UPDATE_SETTINGS_FAILURE';
export var updateTPASettingsRequest = createAction(UPDATE_TPA_SETTINGS_REQUEST);
export var updateTPASettingsSuccess = createAction(UPDATE_TPA_SETTINGS_SUCCESS);
export var updateTPASettingsFailure = createAction(UPDATE_TPA_SETTINGS_FAILURE);
export var updateTPASettings = function (_a, _b) {
    var settings = _a.settings, language = _a.language, translations = _a.translations;
    var _c = _b === void 0 ? {} : _b, _d = _c.host, host = _d === void 0 ? 'NA' : _d, _e = _c.states, states = _e === void 0 ? ['PUBLISHED'] : _e;
    return function (dispatch, getState, _a) {
        var request = _a.request;
        return __awaiter(void 0, void 0, void 0, function () {
            var response, error_2;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        dispatch(updateTPASettingsRequest());
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, request.patch('/v1/settings/instances', __assign({ settings: omit(settings || getTPASettingsData(getState()), [
                                    '_id',
                                    'communityId',
                                ]), // HACK: settings service does not update with empty settings
                                host: host,
                                states: states }, (language && {
                                translations: [
                                    {
                                        languageKey: { languageCode: language },
                                        translations: translations,
                                    },
                                ],
                            })), config)];
                    case 2:
                        response = _c.sent();
                        dispatch(updateTPASettingsSuccess({
                            settings: response.settings,
                            translations: (_b = {}, _b[language] = translations, _b),
                        }));
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _c.sent();
                        dispatch(updateTPASettingsFailure());
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
};
