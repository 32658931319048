import { once } from 'lodash';
import { bindActionCreators } from 'redux';
import {
  SANTA_MEMBERS_APP_ID,
  BLOG_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  isExperimentEnabled,
  biFileUploaded,
  biFileDownloaded,
  fetchTags,
  getCurrentUser,
  fetchAppData,
  FETCH_BLOG_DATA_SUCCESS,
  fetchTPASettings,
  getCustomPostEditUrl,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_VIEWER,
  EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_EDITOR,
} from '@wix/communities-blog-experiments';

import { fetchFeedPostsPromisified } from '../actions/fetch-feed-posts';
import { fetchCategoryPostsPromisified } from '../actions/fetch-category-posts';
import { fetchTagPostsPromisified } from '../actions/fetch-tag-posts';
import { fetchHashtagPostsPromisified } from '../actions/fetch-hashtag-posts';
import { fetchArchivePromisified } from '../actions/fetch-archive';
import { fetchTranslations } from '../store/translations/translations-actions';
import { fetchCurrentUser } from '../store/auth/fetch-current-user';
import {
  initialiseInstanceValues,
  SET_INSTANCE_VALUES,
} from '../store/instance-values/instance-values-actions';
import { initializeMembersNavigation } from '../store/ma-navigation/ma-navigation-actions';
import {
  setAppSettings,
  SET_APP_SETTINGS,
} from '../store/app-settings/app-settings-actions';
import { setBasicParams, SET_BASIC_PARAMS } from '../store/basic-params/basic-params-actions';
import { setCommunitiesContext } from '../store/communities-context/communities-context-actions';
import { setIsLoaded } from '../store/is-loaded/is-loaded-actions';
import { setIsLoading } from '../store/is-loading/is-loading-actions';
import {
  setLocale,
  setQueryLocaleAction,
} from '../store/locale/locale-actions';
import { showHeaderLogin } from '../store/header-widget/show-header-login';
import fetchCategories from '../store/categories/fetch-categories';
import trackEvent from '../actions/track-event';

import { BUTTON_ACTION_CLICK } from '../bi-events/bi-buttons';

import { actionsClosed } from '../actions/actions-closed';
import { actionsOpened } from '../actions/actions-opened';
import { appLoaded } from '../actions/app-loaded';
import { buttonClicked } from '../actions/button-clicked';
import {
  headerSearchOpened,
  headerSearchClosed,
} from '../actions/header-search';
import { incrementPostViewCount } from '../actions/increment-post-view-count';
import {
  navigateInPreview,
  navigateToSectionInPreview,
  navigateWithinBlog,
  navigateToSearch,
  navigateWithinPostPage,
  navigateProGalleryWithinPostPage,
  navigateToCustomPostUrl,
} from '../actions/navigate-within-blog';
import { pageOpened } from '../actions/page-opened';
import {
  requestLoginPromisified,
  requestLogin,
} from '../actions/request-login';
import { showJoinCommunityPopup } from '../actions/join-community';
import { setEnableLinkfify } from '../store/editor-config/editor-config-actions';

import { modalActions } from '../modals/framework/initialize-store';
import { messageActions } from '../messages/framework/initialize-store';

import { fastFormSubmitCallback } from '../components/fast-form/store/fast-form-actions';

import { setUser } from '../store/auth/set-user';
import { resolveUser, getInstance, doRouting } from './helpers';
import getEnvironment from '../services/get-environment';
import { setAppConfig } from '../store/app-config/app-config-actions';
import {
  fetchTopology,
  FETCH_TOPOLOGY_SUCCESS,
} from '../store/topology/topology-actions';
import { fetchPaywallBanner } from '../store/paywall/paywall-actions';
import { getInstanceId } from '../store/instance-values/instance-values-selectors';
import { navigateToPaidPlans } from '../store/paid-plans/paid-plans-actions';
import { emitBlogOpened } from '../actions/blog-socket';
import { fetchMentionsPromisified } from '../actions/fetch-mentions';
import { waitForAction } from '../store/wait-for-action';

import {
  uploadImagePromisified,
  saveAndPublishPostPromisified,
  fetchOembedPromisified,
  addTextColor,
  addCategory,
  createCategoryFromLabelPromisified,
  createCommentPromisified,
  incrementCommentLikeCount,
  incrementPostLikeCount,
  updateCommentPromisified,
  deleteComment,
  createPostDraftPromisified,
  publishPostPromisified,
  savePostDraftPromisified,
  openPostSettings,
  subscribePromisified,
  unsubscribePromisified,
  unpinPostPromisified,
  pinPostPromisified,
  disablePostCommentsPromisified,
  enablePostCommentsPromisified,
  requestFileUploadUrlPromisified,
  requestFileDownloadUrlPromisified,
  resolvePostSeoPatterns,
  submitPostForModerationPromisified,
} from './lazy-actions';
import { setCustomRoutes } from '../store/custom-routes/custom-routes-actions';
import { searchSubmitted } from '../actions/search';
import { categoryLabelClicked } from '../actions/category-label-clicked';
import { postPageMoreButtonClicked } from '../actions/post-page-more-button-clicked';
import { postPageMoreButtonOptionClicked } from '../actions/post-page-more-button-option-clicked';
import { postPageSocialShareClicked } from '../actions/post-page-social-share-clicked';
import { getCurrentMatchEncodedPathname } from '../router/router-selectors';
import { getQueryLocale } from '../selectors/locale-selectors';
import { fetchFeedRenderModel } from '../../feed-page/actions/fetch-feed-render-model';

export function initializeActions({ wixCodeApi, store, fedopsLogger }) {
  return {
    interactionStarted: (interaction) =>
      fedopsLogger.interactionStarted(interaction),
    interactionEnded: (interaction) =>
      fedopsLogger.interactionEnded(interaction),

    navigateToSection: (section) =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then((api) => api.navigateToSection(section)),
    navigateToMember: (memberId) =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then((api) => api.navigateToMember(memberId)),
    logout: () => wixCodeApi.user.logout(),
    navigateToProfile: (memberId, memberSlug) => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        api.navigateToMember({ memberId, memberSlug }).catch(console.error);
      });
    },
    navigateToMyDrafts: (siteMemberIdOrSlug) => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        api
          .navigateToSection({
            appDefinitionId: BLOG_APP_ID,
            sectionId: 'member-drafts-page',
            memberId: siteMemberIdOrSlug,
          })
          .catch(console.error);
      });
    },
    navigateToProfileSettings: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
            sectionId: 'settings',
            memberId: user.siteMemberId,
          })
          .catch(console.error);
      });
    },
    navigateToNotifications: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        api
          .navigateToSection({
            appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
            sectionId: 'notifications',
          })
          .catch(console.error);
      });
    },
    navigateToMyPosts: ({ tab }) => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: BLOG_APP_ID,
            sectionId: "My Posts",
            memberId: user.siteMemberId,
            tpaInnerRoute: `?tab=${tab}`,
          })
          .catch(console.error);
      });
    },
    renderSEOTags: (data) => wixCodeApi.seo.renderSEOTags(data),
    ...bindActionCreators(
      {
        appLoaded: once(appLoaded),
        showJoinCommunityPopup,
        navigateInPreview,
        navigateToSectionInPreview,
        navigateWithinBlog,
        navigateToSearch,
        searchSubmitted,
        navigateWithinPostPage,
        navigateToCustomPostUrl,
        navigateProGalleryWithinPostPage,
        fastFormSubmitCallback,
        requestLogin,
        actionsOpened,
        actionsClosed,
        incrementCommentLikeCount,
        deleteComment,
        incrementPostLikeCount,
        incrementPostViewCount,
        buttonClicked: (data) =>
          buttonClicked({ name: BUTTON_ACTION_CLICK, type: 'post', ...data }),
        goToFeed: () => navigateWithinBlog('/'),
        gotoCreatePost: () => navigateWithinBlog('/create-post'),
        goToEditPage: (id) =>
          navigateToCustomPostUrl(
            getCustomPostEditUrl(store.getState(), id),
            `/${id}/edit`,
          ),
        ...modalActions,
        ...messageActions,
        pageOpened,
        openPostSettings,
        resolvePostSeoPatterns,
        headerSearchOpened,
        headerSearchClosed,
        showHeaderLogin,
        goBack: () => console.log('goBack'), // TODO: fix or remove
        setIsLoading,
        setIsLoaded,
        fileUploaded: biFileUploaded,
        fileDownloaded: biFileDownloaded,
        trackEvent,
        navigateToPaidPlans,
        emitBlogOpened,
        addCategory,
        addTextColor,
        categoryLabelClicked,
        postPageMoreButtonClicked,
        postPageMoreButtonOptionClicked,
        postPageSocialShareClicked,
      },

      store.dispatch,
    ),
  };
}

export function initializePromisifiedActions({ store }, actions = {}) {
  return {
    ...bindActionCreators(
      {
        requestFileUploadUrlPromisified,
        requestFileDownloadUrlPromisified,
        requestLoginPromisified,
        createCommentPromisified,
        updateCommentPromisified,
        uploadImagePromisified,
        savePostDraftPromisified,
        publishPostPromisified,
        subscribePromisified,
        unsubscribePromisified,
        pinPostPromisified,
        unpinPostPromisified,
        enablePostCommentsPromisified,
        disablePostCommentsPromisified,
        duplicatePostPromisified: createPostDraftPromisified,
        fetchFeedPostsPromisified,
        fetchCategoryPostsPromisified,
        fetchTagPostsPromisified,
        fetchArchivePromisified,
        fetchHashtagPostsPromisified,
        saveAndPublishPostPromisified,
        createCategoryFromLabelPromisified,
        fetchOembedPromisified,
        fetchMentionsPromisified,
        submitPostForModerationPromisified,
        ...actions,
      },
      store.dispatch,
    ),
  };
}

const isFeedAdapterEnabled = (state) =>
  [EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_VIEWER,
   EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_EDITOR].some(x => isExperimentEnabled(state, x));

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  translationsName,
  preFetch = () => Promise.resolve(),
  allowPreviewInstance,
  appParams,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());

  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  if (isFeedAdapterEnabled(store.getState())) {
    const loginInfo = resolveUser(wixCodeApi, allowPreviewInstance);
    return Promise.all([
      store.dispatch(setAppSettings({ style: config.style.styleParams })),
      store.dispatch(
        waitForAction(
          [SET_APP_SETTINGS],
          () =>
            setBasicParams({
              viewMode,
              language,
              biPageNumber: platformAPIs.bi.pageNumber,
            }),
          true,
        ),
      ),
      store.dispatch(fetchTopology(instanceId)),
      store.dispatch(
        waitForAction(
          [SET_APP_SETTINGS, SET_BASIC_PARAMS],
          () => fetchFeedRenderModel({ language, translationsName, loginInfo }),
          true,
        ),
      ),
      store.dispatch(initialiseInstanceValues(loginInfo.instance)),
      store.dispatch(initializeMembersNavigation()),
      store.dispatch(setCommunitiesContext()),
      store.dispatch(setEnableLinkfify(instanceId)),
      store.dispatch(setCustomRoutes()),
      store.dispatch(
        waitForAction(
          [FETCH_BLOG_DATA_SUCCESS, FETCH_TOPOLOGY_SUCCESS, SET_INSTANCE_VALUES],
          () => setAppConfig({ bundleName }),
          true,
        ),
      ),
    ]);
  }

  return Promise.all([
    store.dispatch(fetchTopology(instanceId)),
    store.dispatch(
      fetchTPASettings({
        language: getQueryLocale(store.getState()) || language,
      }),
    ),
    initUserDependentStoreBaseData({ store, wixCodeApi, allowPreviewInstance }),
    preFetch(),
    store.dispatch(fetchCategories()),
    store.dispatch(fetchPaywallBanner()),
    store.dispatch(fetchAppData()),
    store.dispatch(initializeMembersNavigation()),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(fetchTranslations(language, translationsName)),
    store.dispatch(setEnableLinkfify(instanceId)),
    store.dispatch(fetchTags({ onlyWithPublishedPosts: true })),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(setCustomRoutes()),
    store.dispatch(
      waitForAction(
        [SET_APP_SETTINGS],
        () =>
          setBasicParams({
            viewMode,
            language,
            biPageNumber: platformAPIs.bi.pageNumber,
          }),
        true,
      ),
    ),
    store.dispatch(
      waitForAction(
        [FETCH_BLOG_DATA_SUCCESS, FETCH_TOPOLOGY_SUCCESS, SET_INSTANCE_VALUES],
        () => setAppConfig({ bundleName }),
        true,
      ),
    ),
  ]);
}

export async function initUserDependentStoreBaseData({
  store,
  wixCodeApi,
  allowPreviewInstance,
}) {
  const user = resolveUser(wixCodeApi, allowPreviewInstance);
  store.dispatch(initialiseInstanceValues(user.instance));

  if (!user.loggedIn) {
    return Promise.resolve();
  }

  return store
    .dispatch(fetchCurrentUser(user.instance))
    .then(() => store.dispatch(setUser(getCurrentUser(store.getState()))))
    .catch(() => {});
}

export function refreshDataOnLogin({ wixCodeApi, store, router }) {
  wixCodeApi.user.onLogin(() =>
    setTimeout(async () => {
      await initUserDependentStoreBaseData({ store, wixCodeApi });

      if (router) {
        const pathname = getCurrentMatchEncodedPathname(store.getState());
        doRouting({ store, router, pathname });
      }
    }),
  );
}
