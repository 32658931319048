import {
  SECTION_CATEGORY,
  getLastPage,
  getMultilingualQueryParam,
} from '@wix/communities-blog-client-common';
import {
  getEntityCount,
  getPaginationPageSize,
} from '../../selectors/pagination-selectors';
import { getArchiveUrl } from '../get-archive-url';
import getPostUrl from '../get-post-url';
import { createArchivePageUrl } from '../navigation';
import { getPaginationUrls } from './get-pagination-item-data';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';

const getMonthLocale = (month, locale) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(parseInt(month, 10) - 1);
  return date.toLocaleString(locale, { month: 'long' });
};

export const generateArchiveSEOTags = ({
  sectionUrl,
  state,
  page,
  posts,
  year,
  month,
  locale,
  multilingual,
}) => {
  const archiveUrl = getArchiveUrl(sectionUrl, year, month);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const items = {
    numberOfItems: entityCount,
    itemUrls: posts.map((post) => getPostUrl(state.topology, [post.slug])),
  };
  const multilingualQueryParam = getMultilingualQueryParam(multilingual);

  const pagination = {
    ...getPaginationUrls(
      archiveUrl,
      page,
      lastPage,
      state,
      multilingualQueryParam,
    ),
    totalPages: parseInt(Math.ceil(entityCount / pageSize), 10),
    currentPage: page,
  };

  const itemData = {
    monthNumber: month,
    monthName: getMonthLocale(month, locale),
    year,
    url: createArchivePageUrl(
      sectionUrl,
      year,
      month,
      page,
      multilingualQueryParam,
    ),
    pagination,
    items,
  };
  return {
    itemType: ITEM_TYPES.BLOG_ARCHIVE,
    itemData: { archive: itemData },
    asNewPage: true,
  };
};
