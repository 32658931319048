import {
  SANTA_MEMBERS_APP_ID,
  MEMBER_INFO_APP_ID,
  BLOG_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { MY_POSTS_WIDGET_ID } from '../../constants/widgets';

export const SET_COMMUNITIES_CONTEXT = 'communitiesContext/SET';

export const isMemberAreaInstalled = (wixCodeApi) =>
  wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: MEMBER_INFO_APP_ID,
    sectionId: 'member_info',
  });

const isMyPostsInstalled = (wixCodeApi) =>
  wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: BLOG_APP_ID,
    sectionId: 'My Posts',
  });

export const setCommunitiesContext = () => async (
  dispatch,
  getState,
  { wixCodeApi }
) => {
  const [memberAreaInstalled, myPostsInstalled] = await Promise.all([
    isMemberAreaInstalled(wixCodeApi),
    isMyPostsInstalled(wixCodeApi),
  ]);

  dispatch({
    type: SET_COMMUNITIES_CONTEXT,
    payload: {
      [SANTA_MEMBERS_APP_ID]: {
        isInstalled: memberAreaInstalled,
      },
      [MY_POSTS_WIDGET_ID]: {
        isInstalled: myPostsInstalled,
      },
    },
  });
};
