import { findIndex, isEqual, pick, slice } from 'lodash';

export const addOrReplaceByKeys = (collection, item, keys) => {
  const index = findIndex(collection, (collectionItem) =>
    isEqual(pick(collectionItem, keys), pick(item, keys)),
  );
  const itemIndex = index === -1 ? collection.length : index;
  return [
    ...slice(collection, 0, itemIndex),
    item,
    ...slice(collection, itemIndex + 1),
  ];
};
