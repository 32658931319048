import { get } from 'lodash';

const isProduction = process.env.NODE_ENV === 'production';

const getIsDebug = (wixCodeApi) => {
  const { debug, ssrDebug } = get(wixCodeApi, 'location.query', {});
  return (debug && debug !== 'false') || ssrDebug;
};

export default function getEnvironment(wixCodeApi) {
  return {
    isEditor: wixCodeApi.window.viewMode === 'Editor',
    isPreview: wixCodeApi.window.viewMode === 'Preview',
    isSite: wixCodeApi.window.viewMode === 'Site',
    isSSR: wixCodeApi.window.rendering.env === 'backend',
    isDebug: getIsDebug(wixCodeApi),
    isProduction,
  };
}
