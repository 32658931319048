import { get } from 'lodash';
import { FETCH_PAYWALL_BANNER_SUCCESS } from './paywall-actions';

export default function paywall(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_PAYWALL_BANNER_SUCCESS:
      return { ...state, banner: get(payload, 'banner') };
    default:
      return state;
  }
}
