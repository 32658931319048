import { merge } from 'lodash';
import { FETCH_TPA_SETTINGS_SUCCESS, UPDATE_TPA_SETTINGS_SUCCESS, } from '../actions/tpa-settings';
export var INITIAL_STATE = { settings: {}, translations: {} };
var tpaSettingsReducer = function (state, _a) {
    if (state === void 0) { state = INITIAL_STATE; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case UPDATE_TPA_SETTINGS_SUCCESS:
        case FETCH_TPA_SETTINGS_SUCCESS:
            return merge(state, payload);
        default:
            return state;
    }
};
export default tpaSettingsReducer;
