import { createAction } from '@wix/communities-blog-client-common';
import { getMultilingualLanguage } from '../../services/get-current-site-language';

export const FETCH_PAYWALL_BANNER_REQUEST = 'paywallBanner/FETCH_REQUEST';
export const FETCH_PAYWALL_BANNER_SUCCESS = 'paywallBanner/FETCH_SUCCESS';
export const FETCH_PAYWALL_BANNER_FAILURE = 'paywallBanner/FETCH_FAILURE';

export const fetchPaywallBannerRequest = createAction(
  FETCH_PAYWALL_BANNER_REQUEST,
);
export const fetchPaywallBannerSuccess = createAction(
  FETCH_PAYWALL_BANNER_SUCCESS,
);
export const fetchPaywallBannerFailure = createAction(
  FETCH_PAYWALL_BANNER_FAILURE,
);

export const fetchPaywallBanner = () => {
  return (dispatch, getState, { paywallRequest, wixCodeApi, flowAPI }) => {
    const language = getMultilingualLanguage(wixCodeApi) || wixCodeApi.site.language;
    dispatch(fetchPaywallBannerRequest());
    return paywallRequest(
      `/banners/default?lang=${language}&site=${wixCodeApi.location.baseUrl}`,
    )
      .then(
        (payload) => dispatch(fetchPaywallBannerSuccess(payload)),
        () => dispatch(fetchPaywallBannerFailure()),
      )
      .then(() => Promise.resolve());
  };
};
