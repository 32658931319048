import {
  createAction,
  handleAggregatorResponse,
} from '@wix/communities-blog-client-common';

export const TAG_CLOUD_WIDGET_SUCCESS = 'tagCloudWidget/SUCCESS';
export const TAG_CLOUD_WIDGET_FAILURE = 'tagCloudWidget/FAILURE';

export const tagCloudWidgetSuccess = createAction(TAG_CLOUD_WIDGET_SUCCESS);
export const tagCloudWidgetFailure = createAction(TAG_CLOUD_WIDGET_FAILURE);

const createTagCloudWidget = (action) => (...args) => (dispatch) =>
  dispatch(action(...args))
    .then((tagCloud) => dispatch(tagCloudWidgetSuccess(tagCloud)))
    .catch(() => tagCloudWidgetFailure());

export const handleTagCloudWidgetResponse = createTagCloudWidget(
  handleAggregatorResponse,
);
