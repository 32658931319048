import { timeoutPromise } from './timeout-promise';
var RETRY_TIMEOUT = 2500;
export var retry = function (fn, url, retries, timeout, abortSignal, isAborted) {
    if (retries === void 0) { retries = 1; }
    if (timeout === void 0) { timeout = RETRY_TIMEOUT; }
    if (abortSignal === void 0) { abortSignal = {}; }
    return new Promise(function (resolve, reject) {
        return timeoutPromise(fn(), timeout, "Fetch timed out after " + timeout + "ms at url: " + url, abortSignal).then(function (response) {
            resolve(response);
        }, function (error) {
            if (error.response && error.response.status >= 500 && retries > 0) {
                return retry(fn, url, retries - 1, timeout, abortSignal).then(resolve, reject);
            }
            if (error.response && error.response.status >= 400) {
                reject(error.response);
            }
            if (isAborted && isAborted(error)) {
                return reject(new DOMException('Aborted', 'AbortError'));
            }
            if (retries > 0) {
                return retry(fn, url, retries - 1, timeout, abortSignal).then(resolve, reject);
            }
            reject(augmentError(error));
        });
    });
};
var augmentError = function (error) {
    if ((error === null || error === void 0 ? void 0 : error.code) === 'ECONNABORTED') {
        try {
            error.name = 'TimeoutError';
        }
        catch (_) { }
    }
    return error;
};
