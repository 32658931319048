import { get } from 'lodash';
import { urijs } from '@wix/communities-blog-client-common';
import { formatTitle } from '../../common/services/pagination';

export const getFeedSEOTags = (state) => state.feedSEOTags;

export const getFeedSEOTitle = (state, { showPagination, page, lastPage, t }) => {
  return showPagination
    ? formatTitle({
        title: get(state, 'feedSEOTags.title', ''),
        page,
        lastPage,
        t,
      })
    : get(state, 'feedSEOTags.title', '');
};
export const getFeedSEOLinks = (state) => get(state, 'feedSEOTags.links', []);
export const getFeedSEOMetaTags = (state, { title }) => {
  const metaTags = get(state, "feedSEOTags.metaTags", []);
  metaTags
    .filter(
      (tag) => tag.property === "og:title" || tag.name === "twitter:title"
    )
    .forEach((tag) => (tag.content = title));
  const ogUrlMetaTagIndex = metaTags.findIndex(
    (item) => item.property === 'og:url',
  );
  if (ogUrlMetaTagIndex > -1) {
    metaTags[ogUrlMetaTagIndex].content = urijs(
      metaTags[ogUrlMetaTagIndex].content,
    ).readable();
  }
  return metaTags;
};
